import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import pt from 'vuetify/es5/locale/pt'

Vue.use(Vuetify)

export default new Vuetify({
    lang: {
        locales: { pt },
        current: 'pt',
    },
    icons: {
        iconfont: 'md',
    },
    theme: {
        themes: {
            light: {
                primary: '#00BCD4',
                secondary: '#383737',
                accent: '#0097A7',
                error: '#cd0000',
                info: '#ff9800',
                success: '#0CBB58',
                warning: '#ff9800'
            },
        },
    },
})

<template>
    <v-app id="reset-pass">
        <v-card class="elevation-12" :loading="loading"
            loader-height="6" :disabled="loading">
            <v-toolbar dark color="primary">
                <v-icon style="margin-right:10px;" size="30" @click="$router.go(-1)">keyboard_return</v-icon>
                <v-toolbar-title>Redefinir Senha</v-toolbar-title>
                <v-spacer />
                <!-- <v-toolbar-items>
                    <v-btn dark text @click.stop.prevent="$router.push({name: 'login'})">Voltar</v-btn>
                </v-toolbar-items> -->
            </v-toolbar>
            <v-card-text>
                <v-form class="p-l-50 p-r-50">
                    <v-row justify="center">
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="usuario.celular"
                                v-maska="'(##) # ####-####'"
                                name="celular" label="Celular" required />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="usuario.senhaAntiga" type="password" label="Senha antiga" required />
                        </v-col>
                        <v-col class="input-icon" cols="12" sm="6">
                            <v-text-field v-model="usuario.novaSenha" label="Senha nova"
                                :type="(viewPass)?'text':'password'" counter required />
                            <v-icon v-if="viewPass" class="view-pass"
                                @click="viewPass = false">visibility</v-icon>
                            <v-icon v-else class="view-pass"
                                @click="viewPass = true">visibility_off</v-icon>
                        </v-col>
                        <!-- <v-col class="input-icon" cols="12" sm="6">
                            <v-text-field v-model="usuario.confirmarSenha"
                                label="Confirmar senha"
                                :type="(viewPass)?'text':'password'"
                                counter required autocomplete="off" />
                            <v-icon v-if="viewPass" class="view-pass"
                                @click="viewPass = false">visibility</v-icon>
                            <v-icon v-else class="view-pass"
                                @click="viewPass = true">visibility_off</v-icon>
                        </v-col> -->
                        <v-col cols="12">
                            <v-btn dark class="ev-color-avulso float-right"
                                @click="submit">Redefinir Senha</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>

        <Snackbar :snackbar-flag="snackbar.activeFlag" :permanent="snackbar.permanentFlag"
            :error="snackbar.errorFlag" :color="snackbar.color"
            :hide-close-buttom="snackbar.isConfirmMode" justify-actions="end">
            <template v-slot:message>{{ snackbar.text }}</template>
            <template v-slot:actions>
                <v-btn v-if="snackbar.isConfirmMode" color="error"
                    @click.prevent="snackbar.confirm = false; snackbar.activeFlag = 0">Não
                </v-btn>
                <v-btn v-if="snackbar.isConfirmMode" color="matchpal"
                    @click.prevent="snackbar.confirm = true; snackbar.activeFlag = 0">Sim
                </v-btn>
            </template>
        </Snackbar>
    </v-app>
</template>

<script>
import Utils from "@components/Utils/Utils.vue"
import { maska } from "maska"

export default {
    name: "RedefinirSenha",
    components: {
        Snackbar: () => import("@components/Snackbar.vue")
    },
    directives: {
        maska
    },
    mixins: [Utils],
    data: () => ({
        usuario: {},
        loading: false,
        dialog: false,
        viewPass: false,
    }),

    mounted() {},

    methods: {
        getObjectPost: function () {
            let post = {
                celular: this.unformatPhone(this.usuario.celular),
                novaSenha: this.usuario.novaSenha,
                senhaAntiga: this.usuario.senhaAntiga,
                // confirmarSenha: this.usuario.confirmarSenha
            }
            return post
        },
        checkInputs: function (post) {
            if (
                !(
                    post.celular &&
                    post.novaSenha &&
                    post.senhaAntiga
                    // && post.confirmarSenha
                )
            ) {
                this.notify('Preencha todos os campos!', false)
                return false
            } else if (post.celular.length != 13) {
                this.notify('Digite um celular válido.', false)
                return false
            } else if (post.novaSenha.length < 8) {
                this.usuario.novaSenha = ''
                this.usuario.confirmarSenha = ''
                this.notify('A senha deve ter mais de 8 caracters.', false)
                return false
            }
            // else if (post.confirmarSenha != post.novaSenha) {
            //     this.usuario.senha = ''
            //     this.usuario.confirmarSenha = ''
            //     this.notify('Os campos senha e confirmar senha são diferentes.', false)
            //     return false
            // }
            return true
        },
        submit(event) {
            let post = this.getObjectPost()
            if (this.checkInputs(post)) {
                {
                    event.preventDefault()
                    this.confirm(
                        'Você tem certeza que deseja salvar redefinir a senha?'
                    ).then(confirm => {
                        if (!confirm) return (this.dialog = false)
                        this.$http({
                            method: 'post',
                            url: '/usuario/redefinirSenha',
                            data: {
                                usuario: JSON.stringify(post)
                            }
                        })
                            .then(response => {
                                alert(response.data.mensagem)
                                return
                                if (response.data.mensagem === 'ok') {
                                    this.notify('Senha alterada com sucesso!', false)
                                    this.$router.push({ path: '/login' })
                                }
                            })
                            .catch(error => {
                                return this.notify('Tente novamente!', true)
                            })
                        this.dialog = false
                    })
                }
            }
        }
    }
}
</script>

<style lang="sass" scoped>
    @import '@assets/css/global-vars.sass'


    #reset-pass
        .v-card__text
            padding: 16px 30px

    .input-icon
        position: relative

    .view-pass
            position: absolute
            top: 31px
            right: 22px
            cursor: pointer
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export default new VueRouter({
    mode: 'history',
    base: '/',
    routes: [
        { path: '/', name: 'agenda',
          component: () => import('@pages/Agenda.vue') },
        { path: '/login', name: 'login',
          component: () => import('@pages/Login.vue') },
        { path: '/planocontratado', name: 'planocontratado',
          component: () => import('@pages/PlanoContratado.vue') },
        { path: '/planocontratado/:faturaId', name: 'fatura',
          component: () => import('@pages/Fatura.vue'), props: true },
        { path: '/administradores', name: 'administradores',
          component: () => import('@pages/Administradores.vue') },
        { path: '/bloquear-agenda', name: 'bloquearagenda',
          component: () => import('@pages/BloquearAgenda.vue') },
        { path: '/cadastro-estabelecimento', name: 'cadastro_estabelecimento',
          component: () => import('@pages/CadastroEstabelecimento.vue') },
        { path: '/editar-estabelecimento', name: 'editar_estabelecimento',
          component: () => import('@pages/CadastroEstabelecimento.vue'),
          props: { action: 'edit' } },
        { path: '/cadastro-usuario', name: 'cadastro_usuario',
          component: () => import('@pages/CadastroUsuario.vue'),
          props: true },
        { path: '/redefinir-senha', name: 'redefinir_senha',
          component: () => import('@pages/RedefinirSenha.vue') },
        { path: '/recuperar-senha', name: 'recuperar_senha',
          component: () => import('@pages/RecuperarSenha.vue') },
        { path: '/quadras', name: 'quadras',
          component: () => import('@pages/Quadras.vue') },
        { path: '/historico', name: 'historico',
          component: () => import('@pages/Historico.vue') },
        { path: '/pix', name: 'pix',
          component: () => import('@pages/Pix.vue') },
        { path: '/notificacoes', name: 'notificacoes',
          component: () => import('@pages/Notificacoes.vue') },
        { path: '*', name: '404',
          component: () => import('@pages/404.vue') },
    ]
})
import Axios from 'axios'


// Inicialização do Axios
const axiosInstance = Axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: process.env.VUE_APP_AXIOS_TIMEOUT,
    headers: {
        'api-version': process.env.VUE_APP_API_VERSION,
    },
    // withCredentials: true,
})

let ignoreUrls = [
    '/refreshToken',
    '/webAuth',
    '/webapp/newUser',
    '/estados',
    '/webapp/loginWeb',
    '/usuario/recuperarSenha', // endpoint to send pin (route: recuperar-senha)
    '/usuario/resetarSenhaPin', // endpoint to alter passwd (route: recuperar-senha)
    '/cidades/getByEstado',
]

// Interceptando a requisição
axiosInstance.interceptors.request.use(function (config) {
    if(!ignoreUrls.includes(config.url)) // if(config.url.match(/api/)) Exemplo de filtro por Regex
        if(typeof config.headers['Authorization'] === 'undefined')
            if(localStorage.getItem('token'))
                config.headers['Authorization'] = localStorage.getItem('token')
            else
                goToLogin()

    return config
})

// Interceptando a respota
axiosInstance.interceptors.response.use(function (response) {
    if(response.status == 200    // Caso adm ter sido removido do
       && !response.data.success // estabelecimento, desloga ele
       && response.data.error_code === 'operacao_sem_estabelecimento') {
        goToLogin()
    } else if(!response.config.isResent // Token Vencido e primeira tentativa
            && response.status == 200
            && response.data.falha == 'token_invalido.') {
        return updateToken().then((responseUpTk) => {
            if(!localStorage.getItem('token')) return Promise.reject(error)
            response.config.headers['Authorization'] = localStorage.getItem('token')
            response.config.isResent = true
            return axiosInstance.request(response.config)
        })
    } else if(response.config.isResent // Token Vencido e retorno do refresh solicitado
            && response.status == 200  // pelo inteceptador do Axios
            && response.data.falha == 'token_invalido.') {
        response.status = 500
        return Promise.reject(error)
    }
    return response
}, function (error){
    console.log( 'error', error )
    return Promise.reject(error)
})


// Funções
let updateToken = () => {
    return axiosInstance({
        method: 'post',
        url: '/refreshToken',
        data: {
            refreshToken: localStorage.getItem('refreshToken'),
        },
        headers: {},
    }).then(function(response) {
        if( response.data.token && response.data.refreshToken
            && response.status == 200 && response.data.mensagem == 'autenticado' )
        {
            localStorage.setItem('token', response.data.token)
            localStorage.setItem('refreshToken', response.data.refreshToken)
        } else {
            goToLogin()
        }
    }).catch(function() {
        goToLogin()
    })
}

let goToLogin = function() {
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    window.location.href = '/login'
}

export default axiosInstance

import Vue from 'vue'
import App from './App.vue'
import routes from './routes'
import axios from '@/plugins/axios'
import vuetify from './plugins/vuetify'
import {firebaseSWInit,setFirebaseToken} from './firebase-init'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@fortawesome/fontawesome-free/css/all.css'
// import '@fortawesome/fontawesome-free/js/all.js'


if (process.env.NODE_ENV !== 'production') {
    Vue.config.productionTip = false
    Vue.config.devtools = true
    Vue.config.performance = true
}

// Add Axios on Vue.js
Vue.use({
    install(Vue) {
        Vue.prototype.$http = axios
    }
})

// Inicialize Vue.js
new Vue({
    name: 'Vue',
    router: routes,
    vuetify,
    render: h => h(App)
}).$mount('#root')

// Service worker registration
if ('serviceWorker' in navigator) {
    window.addEventListener('load', async function() {
        const swRegistration = await navigator.serviceWorker.register('/sw.js')
        firebaseSWInit(swRegistration)
    })
}

// Expose setFirebaseToken to iOS container
window.setFirebaseToken = setFirebaseToken
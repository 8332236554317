<template>
  <a :href="link" :target="target" rel="noopener noreferrer" :class="{'icon-link': icon}">
    <v-icon v-if="icon == 'before'" class="mr-2">fab fa-whatsapp</v-icon>
    <span class="content-link">
      <slot />
    </span>
    <v-icon v-if="icon == 'after'" class="ml-2">fab fa-whatsapp</v-icon>
    <v-icon v-else-if="icon == 'share'" size="30">share</v-icon>
    <template v-if="isEmptySlot && !icon">{{ phone }}</template>
  </a>
</template>

<script>
export default {
    name: "WhatsappLink",
    components: {},
    props: {
        redirect: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: null
        },
        phone: {
            type: String,
            default: ""
        },
        text: {
            type: String,
            default: ""
        },
        source: {
            type: String,
            default: ""
        },
        data: {
            type: String,
            default: ""
        }
    },
    data: () => ({}),
    computed: {
        link: function() {
            return (
                "https://api.whatsapp.com/send" +
        `?phone=${this.phone}&text=${this.text}&source=${this.source}&data=${this.data}`
            )
        },
        target: function() {
            if (this.redirect) return "_self"
            return "_blank"
        },
        isEmptySlot: function() {
            return !this.$slots.default
        }
    },
    watch: {},
    mounted() {},
    methods: {}
}
</script>

<style lang="sass" scoped>
    .content-link,
    .v-icon
        position: relative

        .matchpal,
        .matchpal *
            color: #FFF !important
</style>
<template>
    <v-app id="cad-user">
        <v-card class="elevation-12" :loading="loading"
            loader-height="6" :disabled="loading">
            <v-toolbar dark color="primary">
                <v-btn icon dark @click.stop.prevent="$router.push({name: 'login'})">
                    <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title>Cadastro de Usuário</v-toolbar-title>
                <v-spacer />
                <!-- <v-toolbar-items>
                    <v-btn dark text @click.stop.prevent="$router.push({name: 'login'})">Voltar</v-btn>
                </v-toolbar-items> -->
            </v-toolbar>
            <v-card-text>
                <v-form class="p-l-50 p-r-50">
                    <v-row v-if="step === 0" id="cad-form" justify="center">
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="usuario.nome" name="nome"
                                label="Nome completo" required />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="usuario.cpf" v-maska="'###.###.###-##'"
                                name="cpf" label="CPF" required />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="usuario.celular"
                                v-maska="'(##) # ####-####'"
                                type="tel" name="identifier" label="Celular"
                                autocomplete="username" required />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="usuario.email" name="email"
                                label="E-mail" type="email" required
                                autocomplete="on" autocapitalize="none" />
                        </v-col>
                        <v-col class="input-icon" cols="12" sm="6">
                            <v-text-field v-model="usuario.senha" name="senha"
                                label="Senha" :type="(viewPass)?'text':'password'"
                                counter required />
                            <v-icon v-if="viewPass" class="view-pass"
                                @click="viewPass = false">visibility</v-icon>
                            <v-icon v-else class="view-pass"
                                @click="viewPass = true">visibility_off</v-icon>
                        </v-col>
                        <v-col cols="12" class="text-center">
                            <v-btn color="primary"
                                @click="submit">Enviar e Solicitar PIN</v-btn>
                        </v-col>
                    </v-row>

                    <v-row v-if="step === 1" id="pin" justify="center">
                        <v-col cols="12" class="text-center">
                            <v-row justify="center" no-gutters>
                                <v-col cols="12" sm="10">
                                    <p class="body-1 d-block">Agora precisamos confirmar o seu cadastro e validar o seu número de celular. Você receberá um SMS com o código PIN. Por favor verifique este código e informe abaixo para prosseguir.</p>
                                    <p class="body-2">Caso não tiver recebido o SMS, você pode solicitar novamente seu cadastro voltando na tela anterior.</p>
                                </v-col>
                            </v-row>
                            <v-row justify="center" align="center">
                                <v-col cols="8" sm="6" md="4" lg="2">
                                    <v-text-field v-model="usuario.celular"
                                        v-maska="'(##) # ####-####'"
                                        type="tel" name="identifier" label="Celular"
                                        autocomplete="username" required
                                        disabled dense hide-details outlined />
                                </v-col>
                                
                            </v-row>
                        </v-col>
                        <v-col cols="10" sm="8" md="6" lg="4">
                            <v-text-field v-model="usuario.pin" :counter="4"
                                name="pin" maxlength="4" label="PIN"
                                autocomplete="one-time-code" required />
                        </v-col>
                    </v-row>
                    <v-row v-if="step === 1" id="pin" justify="center">
                        <v-col cols="auto">
                            <v-btn dark class="ev-color-avulso float-right"
                                @click="generateToken">Confirmar</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>

        <Snackbar :snackbar-flag="snackbar.activeFlag" :permanent="snackbar.permanentFlag"
            :error="snackbar.errorFlag" :color="snackbar.color"
            :hide-close-buttom="snackbar.isConfirmMode" justify-actions="end">
            <template v-slot:message>{{ snackbar.text }}</template>
            <template v-slot:actions>
                <v-btn v-if="snackbar.isConfirmMode" color="error"
                    @click.prevent="snackbar.confirm = false; snackbar.activeFlag = 0">Não
                </v-btn>
                <v-btn v-if="snackbar.isConfirmMode" color="matchpal"
                    @click.prevent="snackbar.confirm = true; snackbar.activeFlag = 0">Sim
                </v-btn>
            </template>
        </Snackbar>
    </v-app>
</template>

<script>
import Utils from '@components/Utils/Utils.vue'
import { maska } from 'maska'


export default {
    name: "CadastroUsuario",
    components: {
        Snackbar: () => import('@components/Snackbar.vue'),
    },
    directives: { maska },
    mixins: [Utils],
    props: {
        mode: {
            type: String,
            default: 'cad',
        },
        celular: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        usuario: {},
        loading: false,
        step: 0,
        timeWait: 59,
        waitNewPin: true,
        viewPass: false,
    }),
    mounted() {
        if(this.mode == 'pin') {
            this.step = 1
            this.waitToNewPin()
        }
        if(!!this.celular) {
            this.usuario.celular = this.formatPhone(this.celular)
        }

        this.inicializeReCaptcha()
    },
    destroyed() {
        this.destroyReCaptcha()
    },
    methods: {
        getObjectPost: function () {
            let post = {
                nome: this.usuario.nome,
                cpf: this.usuario.cpf,
                celular: this.unformatPhone(this.usuario.celular),
                email: this.usuario.email,
                senha: this.usuario.senha,
                // confirmarSenha: this.usuario.confirmarSenha
            }
            return post
        },
        checkInputs: function (post) {
            if(this.step === 0) {
                if (
                    !(
                        post.nome &&
                            post.cpf &&
                            post.celular &&
                            post.email &&
                            post.senha
                    )
                ) {
                    this.notify('Preencha todos os campos!', false)
                    return false
                } else if (!this.cpfIsValid(this.usuario.cpf)) {
                    this.notify('Digite um cpf válido.', false)
                    return false
                } else if (post.celular.length != 13) {
                    this.notify('Digite um celular válido.', false)
                    return false
                }
                else if (post.senha.length < 8) {
                    this.usuario.senha = ''
                    this.usuario.confirmarSenha = ''
                    this.notify('A senha deve ter mais de 8 caracters.', false)
                    return false
                }
            } else {
                if (!!post.pin && post.pin.length != 4) {
                    this.notify('Informe o PIN recebido.', false)
                    return false
                }
            }
            return true
        },
        submit(event) {
            let post = this.getObjectPost()
            if (this.checkInputs(post)) {
                this.cadastro = true
                event.preventDefault()
                this.confirm(
                    'Você tem certeza que deseja criar seu usuário com essas informações?'
                ).then(confirm => {
                    if (!confirm) return (this.step = 0)

                    this.loading = true
                    grecaptcha.execute(process.env.VUE_APP_GOOGLE_RECAPTCHA_PUBLIC_KEY)
                        .then((tokenCaptcha) => {
                            this.$http({
                                method: 'post',
                                url: '/webapp/newUser',
                                data: {
                                    usuario: JSON.stringify(post),
                                    grecaptcha: tokenCaptcha,
                                }
                            }).then(response => {
                                if (response.data.mensagem === 'SMS enviado') {
                                    this.step = 1
                                    this.waitToNewPin()
                                    this.loading = false
                                    return this.notify('SMS enviado com sucesso para seu celular', false)
                                } else if (response.data.mensagem === 'cadastro_existente') {
                                    this.step = 0
                                    this.loading = false
                                    return this.notify('Já existe um cadastro com esse número', false)
                                } else {
                                    this.step = 0
                                    return this.notify('Ocorreu um erro, tente novamente', false)
                                }
                                this.loading = false
                            }).catch(error => {
                                return this.notify('Tente novamente!', true)
                                this.step = 0
                                this.loading = false
                            })
                        })
                })
            }
        },
        login: function (token) {
            let post = this.getObjectPost()
            post.PIN = this.usuario.pin
            if (this.checkInputs(post)) {
                grecaptcha.execute(process.env.VUE_APP_GOOGLE_RECAPTCHA_PUBLIC_KEY)
                    .then((tokenCaptcha) => {
                        this.$http({
                            method: 'post',
                            url: '/webapp/loginWeb',
                            data: {
                                usuario: JSON.stringify(post),
                                google_id: token,
                                grecaptcha: tokenCaptcha,
                            }
                        }).then(response => {
                            if (response.data.mensagem == 'autenticado') {
                                localStorage.setItem('token', response.data.token)
                                localStorage.setItem('refreshToken', response.data.refreshToken)
                                if (response.data.isAdm) {
                                    this.$router.push({
                                        path: '/login'
                                    })
                                }else{
                                    this.$router.push({
                                        path: '/cadastro-estabelecimento/'
                                    })
                                }
                            } else {
                                return this.notify('O PIN não está correto, tente novamente!', true)
                            }
                        })
                    })
            }
        },
        generateToken: async function () {
            let token = localStorage.getItem('firebaseToken')
            this.login(token)
        },
        requestPin(event) {
            this.waitNewPin = true

            if (this.unformatPhone(this.usuario.celular).length === 13) {
                grecaptcha.execute(process.env.VUE_APP_GOOGLE_RECAPTCHA_PUBLIC_KEY)
                    .then((tokenCaptcha) => {
                        this.$http({
                            method: 'post',
                            url: '/usuario/recuperarSenha',
                            data: {
                                phone: this.unformatPhone(this.usuario.celular),
                                grecaptcha: tokenCaptcha,
                            },
                        }).then(response => {
                            if (response.data.status === 'success') {
                                this.step = 1
                                this.waitToNewPin()
                                this.notify(response.data.message, false)
                            } else {
                                this.notify(response.data.message, true)
                            }
                        }).catch(error => {
                            return this.notify('Houve um erro. Por favor tente novamente!', true)
                        })
                    })
            } else {
                return this.notify('Houve um erro. Por favor tente novamente!', true)
            }
        },
        waitToNewPin() {
            this.usuario.pin = ''
            this.timeWait = 59
            this.waitNewPin = true
            this.counter()
        },
        counter() {
            setTimeout(() => {
                if(this.timeWait > 0) {
                    this.timeWait--
                    this.counter()
                } else if(this.timeWait === 0)
                    this.waitNewPin = false
            }, 1000)
        },
    }
}
</script>

<style lang="sass" scoped>
    @import '@assets/css/global-vars.sass'


    #cad-user
        .v-card__text
            padding: 16px 30px

    .input-icon
        position: relative

    .view-pass
            position: absolute
            top: 31px
            right: 22px
            cursor: pointer
</style>
import firebase from "firebase/app"
import "firebase/messaging"

const firebaseSWInit = (swRegistration) => {
    if (!firebase.apps.length) {
        firebase.initializeApp({
            apiKey: "AIzaSyCue61DLeKs0hBgC8twDFFEZin8fRXo8Ys",
            authDomain: "androidapp-94f80.firebaseapp.com",
            databaseURL: "https://androidapp-94f80.firebaseio.com",
            projectId: "androidapp-94f80",
            storageBucket: "androidapp-94f80.appspot.com",
            messagingSenderId: "817972715178",
            appId: "1:817972715178:web:1ad70bfefb3e583b827ad3"
        })

        const messaging = firebase.messaging()

        messaging.onMessage((payload) => {
            console.log('[serviceWorker.js] Received foreground message ', payload)
            const sendMessage = async () => {
                const allClients = await clients.matchAll({
                    includeUncontrolled: true
                })

                for (const client of allClients) {
                    client.postMessage("New message.") //json
                }
            }
        })

        messaging.getToken({
            vapidKey: process.env.VUE_APP_FIREBASE_MESSAGING_PUBLIC_KEY,
            serviceWorkerRegistration: swRegistration
        }).then(token => {
            setFirebaseToken(token)
        }).catch(function(err) {
            console.log('Firebase Error: Não foi possível recuperar token.', err)
        })

    } else {
        console.log("firebase.apps.lenght > 0")
    }
}

const setFirebaseToken = (firebaseToken) => {
    if (!!localStorage.getItem('token')) { // Se usuário logado
        if (localStorage.getItem('firebaseToken') != firebaseToken) {
            fetch(`${process.env.VUE_APP_API_URL}apiweb/updateFirebaseToken`, {
                method: 'post',
                body: JSON.stringify({
                    google_id: firebaseToken,
                }),
                headers: new Headers({
                    'Authorization': localStorage.getItem('token'),
                    'api-version': 'dev',
                    'Content-Type': 'application/json;charset=UTF-8',
                }),
            }).then((response) => {
                response.json().then((data) => {
                    if (!!data || data.success === true)
                        localStorage.setItem('firebaseToken', firebaseToken)
                })
            })
        }
    } else {
        // Caso não logado ainda, não precisa atualizar o google_id,
        // pois no momento do login ele é atualizado!
        localStorage.setItem('firebaseToken', firebaseToken)
    }
}

export { firebaseSWInit, setFirebaseToken }
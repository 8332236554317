<template>
    <v-app>
        <v-card>
            <v-app-bar color="matchpal">
                <v-icon style="margin-right:10px;" size="30" @click="$router.go(-1)">keyboard_return</v-icon>
                <v-toolbar-title>Plano Contratado e Faturas</v-toolbar-title>
            </v-app-bar>

            <v-container v-show="showFaturas">
                <v-row dense>
                    <v-col class="col-12">
                        <v-card color="matchpal" dark>
                            <v-card-title class="headline">Plano contratado</v-card-title>
                            <div style="padding-bottom:10px;" align="center">
                                <div v-if="existePlano">
                                    <p>{{ plano.descricao_plano }}</p>
                                    <p>Mensalidade: R$ {{ plano.valor_mensalidade }}</p>
                                    <p>Taxa por confirmação web: R$ {{ plano.valor_mensalidade }}</p>
                                    <p>Limite de cobrança: R$ {{ plano.limite_fatura }}</p>
                                    <v-btn @click="getItem()">{{ titleButton }}</v-btn>
                                </div>
                                <div v-else>
                                    <v-card-subtitle>Período de testes, cobrança não habilitada</v-card-subtitle>
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-card color="matchpalAlert">
                            <v-card-title>{{ title }}</v-card-title>
                        </v-card>
                    </v-col>
                    <v-col v-for="(fatura, i) in faturas" v-show="pendentes" :key="i"
                        class="col-xs-12 col-sm-12 col-md-3 col-12" @click="getFatura(i)">
                        <v-card color="#d3d3d3">
                            <v-card-title class="headline" v-text="fatura.descricao" />
                            <v-card-subtitle v-text="'Vencimento '+fatura.data_vencimento" />
                            <!-- <v-card-actions>
                <v-icon color="#000">{{ fatura.show ? 'expand_less' : 'expand_more' }}</v-icon>
              </v-card-actions> -->
                            <!-- <v-expand-transition>
                <div align="center" v-show="fatura.show">
                  <v-btn color="matchpalAlert">Gerar Boleto</v-btn>
                  <p>{{fatura.descricao_tipo}}</p>
                  <v-col v-for="(item, i) in faturaSelecionada.itens" :key="`A-${i}`" @click.stop="openItem()">
                    <v-card outlined>
                      <v-list-item three-line>
                        <v-list-item-content>
                          <v-list-item-subtitle>{{item.descricao_tipo}} R$ {{item.valor_unitario}}</v-list-item-subtitle>
                          <v-list-item-subtitle>{{item.descricao_data}}</v-list-item-subtitle>
                          <v-list-item-subtitle>
                            {{item.quantidade}} x {{item.valor_unitario}}
                            <v-icon v-if="iconItem.includes(item.tipo)" color="#000">search</v-icon>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </v-col>
                </div>
              </v-expand-transition> -->
                        </v-card>
                    </v-col>
                    <div v-for="(resumo, i) in resumoFaturas" v-show="resumo" :key="`B-${i}`"
                        class="col-xs-12 col-sm-12 col-md-3 col-12" @click="openFaturaResumo(resumo)">
                        <v-card style="padding: 15px;" color="#d3d3d3">
                            <h3 v-text="formatedDate(resumo)" />
                            <h4>R$ {{ resumo.valor_total }}</h4>
                            <h4>{{ resumo.status }}</h4>
                        </v-card>
                    </div>
                </v-row>
            </v-container>
        </v-card>
    </v-app>
</template>

<script>
import moment from 'moment'
import currency from 'currency.js'
import Utils from "@components/Utils/Utils.vue"


moment.locale('pt')

export default {
    name: "Agenda",
    components: {},
    mixins: [ Utils ],
    data: () => ({
        plano: "",
        iconItem: ["boleto", "reserva"],
        showFaturas: true,
        pendentes: true,
        resumo: false,
        existePlano: false,
        title: "Faturas pendentes",
        titleButton: "Resumo faturas",
        faturaSelecionada: {
            id: ""
        },
        resumoFaturas: [],
        faturas: []
    }),
    mounted() {
        this.fetchPlanoContratado()
    },
    methods: {
        fetchPlanoContratado: function () {
            this.$http.post("/financeiro/status").then(response => {
                if (response.data.mensagem == "ok") {
                    this.existePlano = true
                    this.plano = response.data.plano
                    this.plano.valor_mensalidade = this.formatCurrency(this.plano.valor_mensalidade)
                    this.plano.limite_fatura = this.formatCurrency(this.plano.limite_fatura)
                    this.faturas = response.data.faturas_pendente
                    this.faturas.forEach(element => {
                        this.$set(element, "show", false)
                    })
                }
            })
        },
        getFatura: function (i) {
            this.$router.push({
                path: '/planocontratado/' + this.faturas[i].id
            })
        },
        getItem: function () {
            if (this.resumo) {
                this.changeTitles()
                this.resumoFaturas = []
            } else {
                this.$http({
                    method: "post",
                    url: "/financeiro/resumo"
                }).then(response => {
                    this.changeTitles()
                    this.resumoFaturas = response.data.faturas
                    this.resumoFaturas.forEach(element => {
                        element.valor_total = this.formatCurrency(element.valor_total)
                        element.status = this.capitalized(element.status)
                    })
                    //moment().month(number).format("MMMM");
                })
            }
        },
        formatedDate: function (resumo) { //0 é janeiro
            return moment().month(resumo.mes_referencia - 1).format("MMM")
        },
        changeTitles: function () {
            let aux = this.title
            this.title = this.titleButton
            this.titleButton = aux
            this.pendentes = !this.pendentes
            this.resumo = !this.resumo
        },
        openItem: function () {
        },
        openFaturaResumo: function (resumo) {
            this.$router.push({
                path: '/planocontratado/' + resumo.id
            })
        }
    }
}
</script>

<style lang="sass" scoped>
    @import '@assets/css/global-vars.sass'


    p
        color: $white
        font-size: 1.5em
        font-weight: bold
</style>
<template>
    <router-view @logout="logout" />
</template>

<script>

export default {
    name: 'App',
    components: { },
    data: () => ({ }),
    created() {
        const publicRoutes = [
            '404',
            'login',
            'cadastro_usuario',
            'recuperar_senha',
        ]

        this.$router.beforeResolve((to, from, next) => {
            if( !publicRoutes.includes(to.name) ) // É rota privada?
                if( !localStorage.getItem('token') ) // Não está logado?
                    return this.logout() // Força sign-out

            next()
        })
    },
    mounted() {
        setTimeout(() => (
            document.getElementById('loader').classList.add('disabled')
        ), 300)
    },
    methods: {
        logout: function() {
            localStorage.removeItem('token')
            localStorage.removeItem('refreshToken')
            this.$router.push({name: 'login'})
        }
    }
}
</script>

<style lang="sass">
    @import '~@assets/css/global-vars.sass'
    @import '~@assets/css/normalize.min.css'


    html,
    body
        overflow-y: auto !important
        overflow-x: hidden !important

    body
        font-family: Open Sans, Roboto
        font-size: 14px
        color: #383737
        background: #fcfcfc

        .v-application--wrap
            min-height: inherit

        .v-application
            font-family: Open Sans, Roboto

        // Set color buttons v-btn
        .theme--dark.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
            background: $blue

        a,
        .icon-link,
        .icon-link *
            outline: none
            outline-style: none
            outline-color: transparent
            outline-width: 0
            text-decoration: none

        .matchpalAlert
            background-color: $orange !important
        .matchpalAlert .v-card__title
            color: $white
            text-align: center
        .matchpal
            background-color: $blue !important
            border-color: $blue !important

        .matchpal.v-toolbar *,
        .matchpal .v-icon,
        .matchpal.v-btn *
            color: $white !important

        .icon-btn-on-input
            position: relative

        .icon-btn-on-input-right
            position: absolute !important
            top: 50%
            right: 22px
            transform: translateY(-65%)
            font-size: 22px !important
            line-height: 1 !important
            cursor: pointer !important

        .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row
            min-height: 25px

        @media (max-width: 450px)
            .v-navigation-drawer.v-navigation-drawer--fixed
                width: 100% !important

        @media (max-width: 802px)
            body
                font-size: 12px
</style>